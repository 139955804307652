import { render, staticRenderFns } from "./AppError.vue?vue&type=template&id=30fcfd26"
import script from "./AppError.vue?vue&type=script&setup=true&lang=ts"
export * from "./AppError.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./AppError.vue?vue&type=style&index=0&id=30fcfd26&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../../work/modules/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiButton: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/CANYON/CANYON_Deploy_Prod/components/ui/UiButton.vue').default,UiParallelogram: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/CANYON/CANYON_Deploy_Prod/components/ui/UiParallelogram.vue').default})
