var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"cn-error-section"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"cn-error-section__content"},[_c('h1',{staticClass:"cn-error-section__code"},[(_vm.error.statusCode === 404)?_c('span',[_vm._v("404")]):_c('span',[_vm._v("500")])]),_vm._v(" "),_c('p',{staticClass:"cn-error-section__error"},[(_vm.error.statusCode === 404)?_c('span',[_vm._v("\n          "+_vm._s(_vm.$t('page_not_found')))]):_c('span',[_vm._v("Server error")])]),_vm._v(" "),_c('p',{staticClass:"cn-error-section__description"},[_vm._v("\n        "+_vm._s(_vm.$t('something_went_wrong'))+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"cn-error-section__controls"},[_c('UiButton',{attrs:{"variant":"red","to":"/"}},[_vm._v(_vm._s(_vm.$t('home_page')))])],1),_vm._v(" "),_c('h2',{staticClass:"cn-error-section__categories"},[_vm._v("\n        "+_vm._s(_vm.$t('popular_categories'))+"\n      ")]),_vm._v(" "),_c('ul',{staticClass:"cn-error-section__categories-list"},[_vm._l((_setup.menu),function(item,i){return [(item.url)?_c('li',{key:i,staticClass:"cn-error-section__categories-item"},[_c('NuxtLink',{staticClass:"cn-error-section__category",attrs:{"to":item.url}},[(item.image)?_c(_setup.CdnImg,{attrs:{"provider":"imgproxy","src":item.image.path,"width":28,"height":28,"format":"webp"}}):_vm._e(),_vm._v(" "),_c('span',[_vm._v("\n                "+_vm._s(item.name)+"\n              ")])],1)],1):_vm._e()]})],2),_vm._v(" "),_c('div',{staticClass:"cn-error-section__parallelograms"},[_c('UiParallelogram',{staticClass:"cn-error-section__parallelogram-1",style:({
            left: '-230px',
            top: '-120px',
            height: '100px',
            width: '60px',
          }),attrs:{"color":"aqua"}}),_vm._v(" "),_c('UiParallelogram',{staticClass:"cn-error-section__parallelogram-2",style:({
            left: '-180px',
            top: '-260px',
            height: '50px',
            width: '30px',
          }),attrs:{"color":"aqua"}}),_vm._v(" "),_c('UiParallelogram',{style:({
            left: '-520px',
            top: '240px',
            height: '30px',
            width: '20px',
          }),attrs:{"color":"aqua"}}),_vm._v(" "),_c('UiParallelogram',{staticClass:"cn-error-section__parallelogram-4",style:({
            left: '160px',
            top: '-290px',
            height: '100px',
            width: '60px',
          }),attrs:{"color":"aqua"}}),_vm._v(" "),_c('UiParallelogram',{staticClass:"cn-error-section__parallelogram-5",style:({
            left: '180px',
            top: '-140px',
            height: '30px',
            width: '20px',
          }),attrs:{"color":"aqua"}}),_vm._v(" "),_c('UiParallelogram',{style:({
            left: '500px',
            top: '250px',
            height: '90px',
            width: '50px',
          }),attrs:{"color":"aqua"}})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }